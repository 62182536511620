import React from "react"
import Button from "../../components/button"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
import img from "../../images/image (12).webp"
import pdfLink from "../../images/2025 English SBT Catalog.pdf"
import pdfIcon from "../../images/pdf-file.svg"
// import Button from "../components/button"
import "../../styles/homestay.css"
const BachelorOfArts = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container" style={{marginBottom:"0px"}}>
        <div className="content">
        <div className="qualification-crumbs">
            Global University / Bachelor of Arts
          </div>
        <div className="BachelorOfArts section1" style={{display:"flex", gap:"80px", justifyContent:"center", alignItems:"start"}}>
          <div style={{width:"50%"}}>
            <h4>Bachelor of Arts</h4>
            <p className="p-lg">The Bachelor of Arts in Bible and Theology is designed to provide the biblical and theological knowledge necessary for students to carry out
              Christian ministry in the local church and/or to pursue advanced academic studies After successfully completing this curriculum, students
              will be able to properly interpret the Bible, demonstrate a working knowledge of the history and application of Christian theology, preach and
              teach the gospel in a local cultural setting, conduct programs in their local churches that will facilitate the ongoing vitality of the Christian faith,
              and pursue advanced academic studies.
            </p>
            
            <div style={{display:"flex", gap:"15px", justifyContent:"center", alignItems:"center", paddingTop:"20px"}}>
              <a href={pdfLink} target="blank" ><img src={pdfIcon} alt="img" style={{margin:"0px", width:"40px"}}/></a>
              <a href={pdfLink} target="blank" ><p className="p-md" style={{fontSize:"15px",textDecoration:"underline"}}>2025 Global University, Undergraduate School of Bible and Theology Catalog</p></a>
            </div>
            <div style={{marginTop:"30px"}}>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
          </div>
          <div style={{width:"50%"}}>
            <img src={img} alt="img" style={{marginTop:"40px",width:"100%"}}/>
          </div>
        </div>
        </div>
      </div>
      
    </Layout>
  )
}

export default BachelorOfArts
